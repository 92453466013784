import React, { useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import TeamMembersAutocomplete from './TeamMembersAutocomplete'
import { useUsers } from '../../api/aws/useUsers'

const RenderOrgTeam = ({ organization, onRemove, onChange, isUpdating }) => {
  const roles = ['AM', 'PM', 'DMS', 'SEO', 'BD']
  const { users, isUsersLoading, isUsersError } = useUsers()
  const handleSelectNewUser = (role, selectedMemberIds) => {
    const newUserIds = selectedMemberIds.map(user => user.id || user.user_uuid)
    onChange(role, newUserIds)
  }
  const handleRemoveUser = (role, selectedMemberIds) => {
    onRemove(role, selectedMemberIds)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant='h6' sx={{ textAlign: 'center', mb: 2 }}>
        Team Members
      </Typography>
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mb: 2,
        }}
      >
        {roles.map(role => (
          <Grid
            key={role}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{
              p: 0.5,
              minWidth: '85%', // Ensure consistent width
            }}
          >
            <TeamMembersAutocomplete
              role={role}
              label={`Add ${role}`}
              defaultValue={
                organization[role]
                  ? organization[role].map(userId => users.find(user => user.id === userId)).filter(Boolean)
                  : []
              }
              onChange={newMembers => handleSelectNewUser(role, newMembers)}
              onRemove={newMembers => handleRemoveUser(role, newMembers)}
              isUpdating={isUpdating}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RenderOrgTeam

// import React, { useState } from 'react'
// import { Box, Typography, Avatar, Popover, Tooltip, IconButton, CircularProgress } from '@mui/material'
// import TeamMembersAutocomplete from './TeamMembersAutocomplete'
// import PersonAddIcon from '@mui/icons-material/PersonAdd'
// import { useTeamMembers } from '../../api/aws/useTeamMembers'
// import { useUsers } from '../../api/aws/useUsers'
// import { useNotify } from '../../context/NotificationProvider/NotificationProvider'

// const RenderOrgTeam = ({ organization, onUpdate, isUpdating }) => {
//   const [anchorEl, setAnchorEl] = useState(null)
//   const roles = ['AM', 'PM', 'DMS', 'SEO', 'BD']
//   const { createMessagePayload, addNotification } = useNotify()
//   // const roleIds = roles.flatMap(role => organization[role] || []) // Flatten role IDs into a single array
//   const { users, isUsersLoading, isUsersError } = useUsers()
//   // const roleIds = users.flatMap(user => user.user_uuid)
//   // const { teamMembers, isTeamMembersLoading, isTeamMembersError, teamMembersError } = useTeamMembers(roleIds)
//   // const isLoading = isTeamMembersLoading || isUsersLoading
//   const isLoading = isUsersLoading
//   // const isError = isTeamMembersError || isUsersError
//   const isError = isUsersError
//   const handleUpdate = (role, newMembers) => {
//     onUpdate(role, newMembers)
//   }

//   const handlePopoverOpen = event => {
//     setAnchorEl(event.currentTarget)
//   }

//   const handlePopoverClose = () => {
//     setAnchorEl(null)
//   }
// const handleNotify = async (role, userIds) => {
//   /*
//       userId: id,
//       type: type,
//       severity: severity,
//       title: title,
//       message: message,
//       board: board || '',
//       boardId: itemId,
//       url: link || '',
//   */

//   const payload = await createMessagePayload(
//     [userIds],
//     'team',
//     'success',
//     'Added Notification',
//     `Added to team as ${role} | ${organization?.name}`,
//     'organizations',
//     organization?.name,
//     organization?.org_uuid,
//     organization?.photoUrl,
//     `https://grobotic.app/organizations/${organization?.org_uuid}`
//   )
//   await addNotification(payload)
// }

//   const open = Boolean(anchorEl)
//   const hasAssignedMembers = roles.some(role => (organization[role] || []).length > 0)

//   if (isLoading) return <CircularProgress />
//   if (isError) return <Typography>Error loading team members</Typography>

//   return (
//     <>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           width: '100%',
//         }}
//       >
//         <Typography variant='h6' sx={{ textAlign: 'center', mb: 2 }}>
//           Team
//         </Typography>

//         {hasAssignedMembers ? (
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'center',
//               width: '100%',
//             }}
//             onClick={handlePopoverOpen}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'flex-start',
//                 gap: '6px',
//                 maxWidth: '100%',
//                 flexWrap: 'wrap',
//               }}
//             >
//               {roles.flatMap(role =>
//                 (organization[role] || []).map(userId => {
//                   const user = users.find(member => member.id === userId)
//                   return user ? (
//                     <Box
//                       key={`${role}-${user.id}`}
//                       sx={{
//                         transition: 'transform 0.3s ease-in-out',
//                         cursor: 'pointer',
//                         '&:hover': {
//                           transform: 'scale(1.10)',
//                         },
//                       }}
//                     >
//                       <Tooltip title={user.name}>
//                         <Avatar
//                           alt={user.name}
//                           src={user.image_512}
//                           sx={{
//                             height: 50,
//                             width: 50,
//                             cursor: 'pointer',
//                           }}
//                         />
//                       </Tooltip>
//                     </Box>
//                   ) : null
//                 })
//               )}
//             </Box>
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               width: '100%',
//               height: 50,
//             }}
//             onClick={handlePopoverOpen}
//           >
//             <IconButton>
//               <PersonAddIcon />
//             </IconButton>
//           </Box>
//         )}
//       </Box>

//       <Popover
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handlePopoverClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//         slotProps={{
//           paper: {
//             sx: {
//               padding: '10px',
//               maxHeight: '500px',
//               width: '375px',
//             },
//           },
//         }}
//       >
//         <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
//           Organization Team
//         </Typography>
//         {roles.map(role => (
//           <Box key={role} sx={{ mb: 1 }}>
//             <TeamMembersAutocomplete
//               role={role}
//               label={`Add ${role}`}
//               onChange={newMembers => handleUpdate(role, newMembers)}
//               onNotify={handleNotify}
//               error={false}
//               defaultValue={
//                 organization[role]
//                   ? organization[role].map(userId => users.find(member => member.id === userId)).filter(Boolean)
//                   : []
//               }
//               isUpdating={isUpdating}
//             />
//           </Box>
//         ))}
//       </Popover>
//     </>
//   )
// }

// export default RenderOrgTeam
