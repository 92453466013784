import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Avatar, Box, IconButton, AvatarGroup } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { renderUserOption } from '../Users/renderOptions'
import { useUsers } from '../../api/aws/useUsers'

const TeamMembersAutocomplete = ({ role, label, onChange, onRemove, defaultValue = [], isUpdating }) => {
  const { users, isUsersLoading } = useUsers()
  const [selectedUsers, setSelectedUsers] = useState(defaultValue)

  useEffect(() => {
    setSelectedUsers(defaultValue)
  }, [defaultValue])

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue) // Trigger update in the parent component
  }

  const handleRemoveUser = (e, userId) => {
    e.preventDefault()
    const updatedUserIds = selectedUsers.filter(user => user.user_uuid !== userId)
    setSelectedUsers(updatedUserIds)
    onRemove(updatedUserIds)
  }
  return (
    <Autocomplete
      multiple
      options={users || []}
      getOptionLabel={option => `${option.firstName || ''} ${option.lastName || ''}`}
      value={selectedUsers}
      onChange={handleSelectUser}
      renderOption={renderUserOption}
      renderTags={
        (tagValue, getTagProps) => {
          // tagValue.map((option, index) => {
          return (
            <AvatarGroup
              max={4}
              spacing={'small'}
              sx={{ mr: 1, ml: 1 }}
              slotProps={{
                additionalAvatar: {
                  sx: {
                    height: 36,
                    width: 36,
                  },
                },
              }}
            >
              {tagValue.map((option, index) => {
                const { key, onDelete, ...rest } = getTagProps({ index })
                return (
                  <Box
                    key={option.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    {...rest}
                  >
                    {/* <Box key={option.id} sx={{ display: 'flex', alignItems: 'center' }}> */}

                    <Avatar
                      key={option.id}
                      alt={option.real_name}
                      src={option.image_512}
                      sx={{ height: 36, width: 36 }}
                    />

                    {/* <IconButton
                    size='small'
                    onClick={e => handleRemoveUser(e, option.user_uuid || option.id)}
                    sx={{ color: 'white', zIndex: 1 }}
                    >
                    <CancelIcon fontSize='small' />
                    </IconButton> */}
                    {/* </Box> */}
                  </Box>
                )
              })}
            </AvatarGroup>
          )
        }
        // })
      }
      // renderTags={(tagValue, getTagProps) =>
      //   tagValue.map((option, index) => {
      //     const { key, onDelete, ...rest } = getTagProps({ index })
      //     return (
      //       <Box
      //         key={option.id}
      //         sx={{
      //           display: 'flex',
      //           alignItems: 'center',
      //         }}
      //         {...rest}
      //       >
      //         <Avatar src={option.image_512} alt={option.name} sx={{ width: 40, height: 40, mr: 1, ml: 1 }} />

      //         <IconButton
      //           size='small'
      //           onClick={e => handleRemoveUser(e, option.user_uuid || option.id)}
      //           sx={{
      //             padding: '2px',
      //             color: 'rgba(0, 0, 0, 0.7)',
      //             '&:hover': {
      //               color: 'rgba(0, 0, 0, 0.9)',
      //             },
      //           }}
      //         >
      //           <CancelIcon fontSize='small' />
      //         </IconButton>
      //       </Box>
      //     )
      //   })
      // }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          sx={{
            maxheight: '60px', // Consistent height
            '& .MuiAutocomplete-inputRoot': {
              maxHeight: '60px', // Consistent input height
              height: '60px',
            },
            '& .MuiInputBase-root': {
              p: 0,
            },
          }}
        />
      )}
      fullWidth
      sx={{
        minHeight: '60px', // Consistent overall height
      }}
    />
  )
}

export default TeamMembersAutocomplete

// import React, { useState, useEffect } from 'react'
// import { Autocomplete, TextField, Avatar, Box, IconButton, CircularProgress } from '@mui/material'
// import CancelIcon from '@mui/icons-material/Cancel'
// import { renderUserOption } from '../Users/renderOptions'
// import { useUsers } from '../../api/aws/useUsers'

// const TeamMembersAutocomplete = ({ role, label, onChange, onNotify, error, defaultValue = [], isUpdating }) => {
//   const { users, isUsersLoading, isUsersError } = useUsers()
//   const [inputValue, setInputValue] = useState('')
//   const [selectedUsers, setSelectedUsers] = useState(defaultValue)

//   useEffect(() => {
//     setSelectedUsers(defaultValue)
//   }, [defaultValue])

//   const handleSelectUser = (event, newValue) => {
//     setSelectedUsers(newValue)
// if (newValue) {
//   onNotify(role, newValue?.[0]?.user_uuid)
// }
//     onChange(newValue) // Trigger update in the parent component
//   }

// const handleRemoveUser = (e, userId) => {
//   e.preventDefault()
//   const newSelectedUsers = selectedUsers.filter(user => user.user_uuid !== userId)
//   onChange(newSelectedUsers)
// }

//   return (
// <Autocomplete
//   multiple
//   id='team-members-autocomplete'
//   options={users || []}
//   getOptionLabel={option =>
//     option.name
//       ? option.name
//       : `${option.first_name || option.firstName} ${option.last_name || option.lastName} (${option.email})`
//   }
//   value={selectedUsers}
//   onChange={handleSelectUser}
//   inputValue={inputValue}
//   onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
//   isOptionEqualToValue={(option, value) => option.id === value.id}
//   filterOptions={(options, { inputValue }) =>
//     options.filter(option =>
//       `${option.first_name} ${option.last_name}`.toLowerCase().includes(inputValue.toLowerCase())
//     )
//   }
//   renderOption={renderUserOption}
// renderTags={(tagValue, getTagProps) =>
//   tagValue.map((option, index) => {
//     const { key, onDelete, ...rest } = getTagProps({ index })
//     return (
//       <Box
//         key={option.id}
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           margin: '2px',
//         }}
//         {...rest}
//       >
//         <Avatar
//           alt={`${option.first_name} ${option.last_name}`}
//           src={option.image_512}
//           sx={{ width: 38, height: 38, marginRight: '4px' }}
//         />
//         <IconButton
//           size='small'
//           onClick={e => handleRemoveUser(e, option.user_uuid || option.id)}
//           sx={{
//             padding: '2px',
//             color: 'rgba(0, 0, 0, 0.7)',
//             '&:hover': {
//               color: 'rgba(0, 0, 0, 0.9)',
//             },
//           }}
//         >
//           <CancelIcon fontSize='small' />
//         </IconButton>
//       </Box>
//     )
//   })
// }
//   renderInput={params => (
//     <TextField
//       {...params}
//       label={label}
//       error={error}
//       InputProps={{
//         ...params.InputProps,
//         endAdornment: (
//           <>
//             {isUpdating ? <CircularProgress color='inherit' size={20} sx={{ marginRight: '10px' }} /> : null}
//             {params.InputProps.endAdornment}
//           </>
//         ),
//       }}
//     />
//   )}
//   fullWidth
// />
//   )
// }

// export default TeamMembersAutocomplete
