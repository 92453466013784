import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDealsByIds } from '../../api/aws/useDealByIds'
import { useOrganizationById } from '../../api/aws/useOrganization'
import Loading from '../Loading/Loading'
import { formatDayjsDate } from '../DataGrid/createColumns/renderDealsColumns'
import { StyledLink } from './style'
import dayjs from 'dayjs'
import { renderColorByString } from '../DataGrid/renderColorByString'

const OrgDealInfo = () => {
  const { orgId } = useParams()
  const {
    organization,
    organizationLoading,
    organizationError,
    organizationDetailsError,
  } = useOrganizationById(orgId)

  const dealIds = organization?.dealIds || []
  const {
    deals: deals,
    isLoading: dealsLoading,
    isError: dealsError,
    error: dealsErrorDetails,
  } = useDealsByIds(dealIds)
  console.log('deals: ', deals)

  if (organizationLoading) {
    return <Loading />
  }

  if (organizationError) {
    return (
      <Typography>
        Error loading organization details: {organizationDetailsError.message}
      </Typography>
    )
  }

  // Helper function to calculate total amount from deals
  const getTotalContractAmount = (products) => {
    // Check if products is defined and is an array
    if (!products || !Array.isArray(products)) return '0.00'

    return products
      .reduce((acc, product) => {
        return acc + (parseFloat(product.price) || 0)
      }, 0)
      .toFixed(2) // Format the result to two decimal places
  }
  const formatDateString = (date) => {
    if (!date) return ''
    return dayjs.isDayjs(date)
      ? date.format('MM/DD/YYYY')
      : dayjs(date).format('MM/DD/YYYY')
  }
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="deals table">
          <TableHead>
            <TableRow>
              <TableCell>Deal Name</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Stage</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Total Contract Amount ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deals.map((deal) => {
              return (
                <TableRow key={deal.deal_uuid}>
                  <TableCell component="th" scope="row">
                    <StyledLink to={`/deals/${deal.deal_uuid}`}>
                      <Box>{deal.name || 'No Name'}</Box>
                    </StyledLink>
                  </TableCell>
                  <TableCell
                    sx={{ color: renderColorByString(deal.type) }}
                    align="right"
                  >
                    {deal.type}
                  </TableCell>
                  <TableCell align="right">{deal.stage}</TableCell>
                  <TableCell align="right">
                    {formatDateString(deal.startDate)}
                  </TableCell>
                  <TableCell align="right">
                    {formatDateString(deal.endDate)}
                  </TableCell>
                  <TableCell align="right">{`$${getTotalContractAmount(
                    deal.products
                  )}`}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OrgDealInfo

// import React from 'react'
// import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material'
// import { useParams } from 'react-router-dom'
// import { useDealsByIds } from '../../api/aws/useDealByIds'
// import { useOrganizationById } from '../../api/aws/useOrganization'
// import Loading from '../Loading/Loading'
// import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'

// const DealInformation = () => {
//   const { orgId } = useParams()
//   const {
//     data: organization,
//     isLoading: organizationLoading,
//     isError: organizationError,
//     error: organizationDetailsError,
//   } = useOrganizationById(orgId)

//   const dealIds = organization?.dealIds || []
//   const { data: deals, isLoading: dealsLoading, isError: dealsError, error: dealsErrorDetails } = useDealsByIds(dealIds)
//   console.log('deals: ', deals)

//   if (organizationLoading || dealsLoading) {
//     return <Loading />
//   }

//   if (organizationError) {
//     return <Typography>Error loading organization details: {organizationDetailsError.message}</Typography>
//   }

//   if (dealsError) {
//     return <Typography>Error loading deals: {dealsErrorDetails.message}</Typography>
//   }

//   if (!deals || deals.length === 0) {
//     return <Typography>No current deals.</Typography>
//   }

//   // Helper function to calculate total amount from contracts
//   const getTotalContractAmount = contracts => {
//     // Check if contracts is defined and is an array
//     if (!contracts || !Array.isArray(contracts)) return '0.00'

//     return contracts
//       .reduce((acc, contract) => {
//         // Ensure contract and contract.products are defined before reducing
//         if (!contract || !Array.isArray(contract.products)) return acc

//         return (
//           acc +
//           contract.products.reduce((prodAcc, product) => {
//             // Check each product's total and parse it as a float, defaulting to 0 if undefined
//             return prodAcc + (parseFloat(product.total || product.price) || 0)
//           }, 0)
//         )
//       }, 0)
//       .toFixed(2) // Format the result to two decimal places
//   }

//   return (
//     <Box sx={{ width: '100%' }}>
//       <TableContainer component={Paper}>
//         <Table sx={{ minWidth: 650 }} aria-label='deals table'>
//           <TableHead>
//             <TableRow>
//               <TableCell>Deal Name</TableCell>
//               <TableCell align='right'>Type</TableCell>
//               <TableCell align='right'>Stage</TableCell>
//               <TableCell align='right'>Start Date</TableCell>
//               <TableCell align='right'>End Date</TableCell>
//               <TableCell align='right'>Total Contract Amount ($)</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {deals.map(deal => (
//               <TableRow key={deal.deal_uuid}>
//                 <TableCell component='th' scope='row'>
//                   {deal.name || 'No Name'}
//                 </TableCell>
//                 <TableCell sx={{ color: renderTypeColor(deal.type) }} align='right'>
//                   {deal.type}
//                 </TableCell>
//                 <TableCell align='right'>{deal.stage}</TableCell>
//                 <TableCell align='right'>{deal.startDate}</TableCell>
//                 <TableCell align='right'>{deal.endDate}</TableCell>
//                 <TableCell align='right'>{getTotalContractAmount(deal.contracts)}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   )
// }

// export default DealInformation
