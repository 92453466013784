export const renderColorByString = (string, theme) => {
  switch (string) {
    case 'Marketing':
    case 'MSA':
    case 'RMSA':
    case 'MSA - Housing':
    case 'RMSA - Housing':
    case 'IO':
      return 'rgb(88, 188, 128)'
    case 'Creative':
    case 'CSA':
    case 'RCSA':
      return 'rgb(165, 147, 255)'
    case 'draft':
      return 'rgb(167, 172, 182)'
    case 'Draft':
      return 'rgb(167, 172, 182)'
    case 'saved':
      return 'rgb(35, 146, 173)'
    case 'Saved':
      return 'rgb(35, 146, 173)'
    case 'sent':
      return 'rgb(108, 218, 135)'
    case 'Sent':
      return 'rgb(93, 79, 251)'
    case 'created':
      return 'rgb(114, 229, 218)'
    case 'Created':
      return 'rgb(114, 229, 218)'
    case 'Active':
      return 'rgb(80, 200, 120)'
    case 'Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Rejected':
    case 'Expired':
      return 'rgba(220, 20, 60, 0.9)'
    case 'Onboarding':
      return 'rgb(110, 193, 232)'
      return 'rgba(137, 196, 244, 1)'
    case 'Awaiting Dependencies':
      return 'rgb(59, 152, 245)'
    case 'Dependencies Received':
      return 'rgb(245, 124, 79)'
    case 'Handoff Call':
      return 'rgba(106, 65, 192, 1)'
    case 'Launched':
      return 'rgb(52, 211, 145)'
    case 'Cancelled':
      return theme ? theme.palette.text.primary : 'rgba(0,0,0)'
    case 'Viewed':
      return 'rgb(213, 102, 201)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 1)'
    case 'Needs Approval':
      return 'rgb(251, 193, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 1)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 1)'
    case 'Completed':
      return 'rgb(80, 200, 120)'
    case 'Proposal':
      return 'rgb(174, 92, 174)'
    case 'Invoiced':
      return 'rgb(80, 200, 120)'
    case 'Not Invoiced':
      return 'rgb(249, 167, 49)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208)'
    case 'Month To Month':
      return 'rgb(22, 138, 239)'
    case 'N/A':
      return 'rgb(121, 126, 147)'
    case 'Requested':
      return 'rgb(253, 188, 100)'
    case 'Done':
      return 'rgb(51, 211, 145)'
    case 'In Progress':
      return 'rgb(253, 188, 100)'
    case 'Stuck':
      return 'rgb(232, 105, 125)'
    case 'draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'Draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'Saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'sent':
      return 'rgb(108, 218, 135, 0.6)'
    case 'Sent':
      return 'rgb(93, 79, 251, 0.6)'
    case 'created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Active':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Approved':
      return 'rgb(6, 255, 167, 1)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case 'Rejected':
      return 'rgba(220, 20, 60, 0.8)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Viewed':
      return 'rgb(213, 102, 201, 0.6)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69, 0.6)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205, 0.6)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 0.6)'
    case 'Needs Approval':
      return 'rgb(245, 189, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 0.6)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 0.6)'
    case 'Completed':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Proposal':
      return 'rgb(174, 92, 174, 0.6)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208, 0.6)'
    case 'Month To Month':
      return 'rgb(22, 138, 239, 0.6)'
    case 'Prospect':
      return 'rgb(93, 79, 251)'
    case 'Onboarding':
      return 'rgb(92, 179, 253)'
    case 'In-Progress':
      return 'rgb(110, 156, 222)'
    case 'Active':
      return 'rgb(152, 214, 129)'
    case 'Active (SEO)':
      return 'rgb(152, 98, 161)'
    case 'Active (Creative)':
      return 'rgb(147, 111, 218)'
    case 'Active (Website)':
      return 'rgb(102, 69, 169)'
    case 'Active (Past Due)':
      return 'rgb(255, 131, 88)'
    case 'Active (LTP)':
      return 'rgb(51, 158, 205)'
    case '30 day notice':
      return 'rgb(235, 77, 77)'
    case 'Cancelled':
      return 'rgb(0,0,0)'
    case 'Inactive / Paused':
      return 'rgb(255, 145, 145)'
    default:
      return 'rgba(178, 190, 181, 0.6)'
  }
}
