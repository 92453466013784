import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { ncoTeamTasks, ncoDependenciesList, dependenciesNameMapping } from './ncoTaskObjects'
import { useUsers } from '../../api/aws/useUsers'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import RenderRoleTasks from './RenderRoleTasks'
import Channels from './Channels'

const NcoCardsRow = ({ updates, setUpdates, initialValues }) => {
  const { newDeal: deal, setNewDeal: setDeal } = useDealDetailsContext()
  const [testLoading, setTestLoading] = useState(true)
  const [currentDeal, setCurrentDeal] = useState(deal ?? {})
  const { users } = useUsers()
  const cursorStyle = testLoading ? { cursor: 'wait' } : {}

  const handleFieldChange = (fieldName, newValue) => {
    if (initialValues[fieldName] !== newValue) {
      setUpdates(prev => ({ ...prev, [fieldName]: newValue }))
      setDeal(prev => ({
        ...prev,
        [fieldName]: newValue,
      }))
    }
  }

  const handleRoleTaskChange = (taskGroupName, value, role) => {
    console.log('taskGroupName, value, role', taskGroupName, value, role)
    const roleTasksPropertyName = `${role.toLowerCase()}Tasks`
    const updatedRoleTasks = {
      ...deal[roleTasksPropertyName],
      [taskGroupName]: value,
    }
    console.log('updatedRoleTasks', updatedRoleTasks)
    if (deal?.roleTasksPropertyName?.taskGroupName !== value) {
      setUpdates(prev => ({ ...prev, [taskGroupName]: value }))
      setDeal(prevDetails => ({
        ...prevDetails,
        [roleTasksPropertyName]: updatedRoleTasks,
      }))
    }
  }
  const dependencyNames = ncoDependenciesList[0].Dependency.map(dep => Object.keys(dep)[0])
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography>Dependencies</Typography>
      <Channels
        deal={deal}
        dependencyList={ncoDependenciesList}
        handleChange={handleFieldChange}
        dependenciesNameMapping={dependenciesNameMapping}
      />
      <Typography>Role Tasks</Typography>
      {['PM', 'DMS', 'MIA', 'AM', 'QA', 'SEO'].map(role => (
        <RenderRoleTasks
          key={role}
          role={role}
          deal={initialValues}
          updates={updates}
          onChange={handleFieldChange}
          users={users}
          tasks={ncoTeamTasks[0][role]}
          onRoleTaskChange={handleRoleTaskChange}
        />
      ))}
    </Grid>
  )
}
export default NcoCardsRow
