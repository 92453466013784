import React, { useState, useEffect } from 'react'
import { Drawer, Box, Typography, useTheme, Grid, useMediaQuery, Tabs, Tab } from '@mui/material'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
import NcoCardsRow from './NcoCardsRow'
import NcoDrawerHeader from './NcoDrawerHeader'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, Outlet, useLocation, useParams } from 'react-router-dom'
import Strategy from '../Strategy/Strategy'
import NcoStrategy from './NcoStrategy'
import RenderCampaign from '../StrategyCampaigns/RenderCampaign'

const Notes = () => <Typography>Notes Component</Typography>

const NcoDrawer = ({ open, onClose }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { dealId, campaignId } = useParams()
  const { newDeal } = useDealDetailsContext()
  const theme = useTheme()
  const { mutate: updateDeal } = useUpdateDeal()
  const [updates, setUpdates] = useState({})
  const queryClient = useQueryClient()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
    newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  )
  const [initialValues, setInitialValues] = useState(newDeal ?? {})
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
    if (newValue === 0) navigate('.')
    if (newValue === 1) navigate(`./${dealId}/strategy`)
    if (newValue === 2) navigate(`./notes`)
  }
  // Determine active tab based on the URL
  useEffect(() => {
    if (location.pathname.includes(`strategy/${campaignId}`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`strategy`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`notes`)) {
      setActiveTab(2)
    } else {
      setActiveTab(0)
    }
  }, [location])
  useEffect(() => {
    if (newDeal) {
      setInitialValues(newDeal)
    }
  }, [newDeal])

  const handleUpdate = () => {
    if (Object.keys(updates).length === 0) return

    const dealId = newDeal?.deal_uuid
    const dealData = { ...initialValues, ...updates }

    updateDeal(
      { dealId, dealData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['deals', dealId])
          setUpdates({})
        },
        onError: error => {
          console.error('Error updating deal:', error)
        },
      }
    )
  }
  const handleClose = () => {
    handleUpdate()
    onClose()
    setUpdates({})
  }
  useEffect(() => {
    if (updates) {
      console.log('updates', updates)
    }
  }, [updates])

  // Call useMediaQuery only at the top level
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  // Calculate drawerWidth based on screen size
  let drawerWidth = '100%'
  if (isExtraLargeScreen) {
    console.log('XL SCREEN')
    drawerWidth = '85%'
  } else if (isLargeScreen) {
    console.log('LG SCREEN')
    drawerWidth = '65%'
  } else if (isMediumScreen) {
    console.log('MD SCREEN')
    drawerWidth = '75%'
  } else if (isSmallScreen) {
    console.log('SM SCREEN')
    drawerWidth = '85%'
  }

  if (isOrganizationError) {
    return <Typography>Organization Error...</Typography>
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: drawerWidth,
          overflowX: 'hidden',
          // p: 1,
          // height: '93.2vh'
        },
      }}
    >
      <Box>
        {/* Header */}
        <NcoDrawerHeader newDeal={newDeal} />

        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tab label='Main' />
          <Tab label='Strategy' />
          <Tab label='Notes' />
        </Tabs>

        {/* Tab Content */}
        <Box>
          {activeTab === 0 && <NcoCardsRow updates={updates} setUpdates={setUpdates} initialValues={newDeal} />}
          {activeTab === 1 && location.pathname.includes(`strategy/${campaignId}`) ? (
            <RenderCampaign />
          ) : activeTab === 1 && location.pathname.includes(`strategy`) ? (
            <Strategy />
          ) : null}
          {activeTab === 2 && <Notes />}
        </Box>
        <Outlet />
      </Box>
    </Drawer>
  )
}

export default NcoDrawer

// import React, { useState, useEffect } from 'react'
// import { Drawer, Box, Typography, useTheme, Grid, useMediaQuery, Tabs, Tab } from '@mui/material'
// import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
// import { useOrganizationById } from '../../api/aws/useOrganization'
// import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'
// import NcoCardsRow from './NcoCardsRow'
// import NcoDrawerHeader from './NcoDrawerHeader'
// import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
// import { useQueryClient } from '@tanstack/react-query'
// import { Link } from 'react-router-dom'
// import Strategy from '../Strategy/Strategy'

// const Notes = () => <Typography>Notes Component</Typography>

// const NcoDrawer = ({ open, onClose }) => {
//   const { newDeal } = useDealDetailsContext()
//   const theme = useTheme()
//   const { mutate: updateDeal } = useUpdateDeal()
//   const [updates, setUpdates] = useState({})
//   const queryClient = useQueryClient()
//   const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
//     newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
//   )
//   const [initialValues, setInitialValues] = useState(newDeal ?? {})
//   const [activeTab, setActiveTab] = useState(0)

//   const handleTabChange = (event, newValue) => {
//     console.log('newValue', newValue)
//     setActiveTab(newValue)
//   }

//   useEffect(() => {
//     if (newDeal) {
//       setInitialValues(newDeal)
//     }
//   }, [newDeal])

//   const handleUpdate = () => {
//     if (Object.keys(updates).length === 0) return

//     const dealId = newDeal?.deal_uuid
//     const dealData = { ...initialValues, ...updates }

//     updateDeal(
//       { dealId, dealData },
//       {
//         onSuccess: () => {
//           queryClient.invalidateQueries(['deals', dealId])
//           setUpdates({})
//         },
//         onError: error => {
//           console.error('Error updating deal:', error)
//         },
//       }
//     )
//   }
//   const handleClose = () => {
//     handleUpdate()
//     onClose()
//     setUpdates({})
//   }
//   useEffect(() => {
//     if (updates) {
//       console.log('updates', updates)
//     }
//   }, [updates])

//   // Call useMediaQuery only at the top level
//   const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
//   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
//   const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
//   const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

//   // Calculate drawerWidth based on screen size
//   let drawerWidth = '100%'
//   if (isExtraLargeScreen) {
//     console.log('XL SCREEN')
//     drawerWidth = '75%'
//   } else if (isLargeScreen) {
//     console.log('LG SCREEN')
//     drawerWidth = '65%'
//   } else if (isMediumScreen) {
//     console.log('MD SCREEN')
//     drawerWidth = '75%'
//   } else if (isSmallScreen) {
//     console.log('SM SCREEN')
//     drawerWidth = '85%'
//   }

//   if (isOrganizationError) {
//     return <Typography>Organization Error...</Typography>
//   }

//   return (
//     <Drawer
//       anchor='right'
//       open={open}
//       onClose={handleClose}
//       PaperProps={{
//         sx: { width: drawerWidth, overflowX: 'hidden', p: 1, height: '93.2vh' },
//       }}
//     >
//       <Box>
//         {/* Header */}
//         <NcoDrawerHeader newDeal={newDeal} />

//         {/* Tabs */}
//         <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
//           <Tab label='Main' />
//           <Tab label='Strategy' />
//           <Tab label='Notes' />
//         </Tabs>

//         {/* Tab Content */}
// <Box sx={{ p: 2 }}>
//   {activeTab === 0 && <NcoCardsRow updates={updates} setUpdates={setUpdates} initialValues={newDeal} />}
//   {activeTab === 1 && <Strategy dealId={newDeal?.deal_uuid} />}
//   {activeTab === 2 && <Notes />}
// </Box>
//       </Box>
//     </Drawer>
//   )
// }

// export default NcoDrawer
