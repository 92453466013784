import { Route, Routes } from 'react-router-dom'
import AppLayout from './AppLayout'
import { SnackbarProvider } from './context/SnackbarProvider/SnackbarProvider'
import OrganizationsPage from './components/Organizations/OrganizationsPage'
import OrganizationPage from './components/Organizations/OrganizationPage'
import Leads from './components/Leads/Leads'
import Documents from './components/PandaDoc/Documents'
import DocumentDetails from './components/PandaDoc/DocumentDetails'
import DealsPage from './components/Deals/DealsPage'
import ContactDetails from './components/Contacts/ContactDetails'
import SlackUserList from './components/SlackUserList/SlackUserList'
import { HeaderProvider } from './context/HeaderProvider/HeaderProvider'
import DashboardPage from './components/Dashboard/DashboardPage'
import { LoadingProvider } from './context/LoadingProvider/LoadingProvider'
import { PandaProvider } from './context/PandaProvider/PandaProvider'
import DealsDetails from './components/Deals/DealsDetails'
import OrganizationDetails from './components/Organizations/OrganizationDetails'
import OrgDealInfo from './components/Organizations/OrgDealInfo'
import OrgBillingInfo from './components/Organizations/OrgBillingInfo'
import OrgTaskInfo from './components/Organizations/OrgTaskInfo'
import OrganizationNotes from './components/Organizations/OrganizationNotes'
import DealsKanban from './components/DealsKanban/DealsKanban'
import DealTaskInfo from './components/Deals/DealTaskInfo'
import DealServicesInfo from './components/Deals/DealServicesInfo'
import DealPage from './components/Deals/DealPage'
import ContactsPage from './components/Contacts/ContactsPage'
import { ValidationProvider } from './context/ValidationProvider/ValidationProvider'
import PaymentSchedule from './components/PaymentSchedule/PaymentSchedule'
import { LeadsProvider } from './context/LeadsProvider/LeadsProvider.js'
import Strategy from './components/Strategy/Strategy.js'
import ReviewDeal from './components/DealApproval/ReviewDeal.js'
import ContactPage from './components/Contacts/ContactPage.js'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AccountingSchedule from './components/PaymentSchedule/AccountingSchedule.js'
import ApprovalsPage from './components/Approvals/ApprovalsPage.js'
import SprintsPage from './components/Sprints/SprintsPage.js'
import NCOPage from './components/NCO/NCOPage.js'
import TimelinePage from './components/Timeline/TimelinePage.js'
import RenderCampaign from './components/StrategyCampaigns/RenderCampaign.js'
import AccountingPage from './components/Accounting/AccountingPage.js'
import IntegrationNotes from './components/Integration/IntegrationNotes.js'
import MentionsPage from './components/Mentions/MentionsPage.js'
import PaginateDeals from './components/Deals/PaginateDeals.js'
import NcoDrawer from './components/NCO/NcoDrawer.js'
import { Typography } from '@mui/material'

const ProtectedApp = () => {
  return (
    <ValidationProvider>
      <LeadsProvider>
        <SnackbarProvider>
          <PandaProvider>
            <LoadingProvider>
              <HeaderProvider>
                <AppLayout>
                  {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                  <Routes>
                    <Route path='/dashboard' element={<DashboardPage />} />
                    {/* NCO Routes */}
                    <Route path='/nco/*' element={<NCOPage />}>
                      <Route path=':dealId/*' element={<NcoDrawer />}>
                        <Route path='strategy' element={null} />
                        <Route path='strategy/:campaignId' element={null} />
                        <Route path='notes' element={<Typography>Notes Component</Typography>} />
                      </Route>
                    </Route>

                    <Route path='/accounting' element={<AccountingPage />} />
                    <Route path='/leads' element={<Leads />} />
                    <Route path='/approvals' element={<ApprovalsPage />} />
                    <Route path='/notifytags' element={<MentionsPage />} />
                    <Route path='/deals' element={<DealsPage />} />
                    {/* <Route path='/deals' element={<PaginateDeals />} /> */}
                    <Route path='/deals/kanban' element={<DealsKanban />} />
                    <Route path='/deals/:dealId/*' element={<DealPage />}>
                      <Route path='' element={<DealsDetails />} />
                      <Route path='tasks' element={<DealTaskInfo />} />
                      <Route path='services' element={<DealServicesInfo />} />
                      <Route path='strategy' element={<Strategy />} />
                      <Route path='strategy/:campaignId' element={<RenderCampaign />} />
                      <Route path='reviewdeal' element={<ReviewDeal />} />
                      <Route path='paymentschedule' element={<PaymentSchedule />} />
                      <Route path='invoicing' element={<AccountingSchedule />} />
                      <Route path='timeline' element={<TimelinePage />} />
                    </Route>
                    <Route path='/contacts' element={<ContactsPage />} />
                    <Route path='/contacts/:contactId/*' element={<ContactPage />}>
                      <Route path='' element={<ContactDetails />} />
                      <Route path='additionalinfo' element={<div>additionalInfo</div>} />
                    </Route>
                    <Route path='/organizations' element={<OrganizationsPage />} />
                    <Route path='/organizations/:orgId/*' element={<OrganizationPage />}>
                      <Route path='' element={<OrganizationDetails />} />
                      <Route path='deals' element={<OrgDealInfo />} />
                      <Route path='tasks' element={<OrgTaskInfo />} />
                      <Route path='billing' element={<OrgBillingInfo />} />
                      <Route path='notes' element={<OrganizationNotes />} />
                      <Route path='integration' element={<IntegrationNotes />} />
                    </Route>
                    {/* <Route path='/propertymgmt/:propertyMgmtId' element={<PropertyMgmtCompany />} /> */}
                    {/* <Route path='/ownershipGroup/:ownershipGroupId' element={<OwnershipGroup />} /> */}
                    <Route path='/sprints' element={<SprintsPage />} />
                    {/* <Route path='/updatecomponents' element={<UpdateComponents />} /> */}
                    <Route path='/pandadocs' element={<Documents />} />
                    <Route path='/pandadocs/:name' element={<DocumentDetails />} />
                    <Route path='/slackuserlist' element={<SlackUserList />} />
                    {/* <Route path='/timeline' element={<WebhookData />} /> */}
                  </Routes>
                </AppLayout>
              </HeaderProvider>
            </LoadingProvider>
          </PandaProvider>
        </SnackbarProvider>
      </LeadsProvider>
    </ValidationProvider>
  )
}

export default ProtectedApp
/**
              <Route
              path='/teamtasks'
              element={
                <TaskProvider>
                  <TeamTasks />
                </TaskProvider>
              }
            />
 */
