import React, { useState } from 'react'
import RenderOrgTeam from './RenderOrgTeam'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useQueryClient } from '@tanstack/react-query'
import { Box } from '@mui/material'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'

const TeamMembers = ({ organization }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [localOrg, setLocalOrg] = useState(organization) // Maintain optimistic state
  const { mutate: updateItems } = useUpdateItems()
  const { createMessagePayload, addNotification } = useNotify()

  const queryClient = useQueryClient()

  const handleChange = (role, selectedUserIds) => {
    setLocalOrg(prev => ({
      ...prev,
      [role]: selectedUserIds,
    }))
    handleUpdateOrganization(role, selectedUserIds)
    handleNotify(role, selectedUserIds)
  }
  const handleRemove = (role, selectedUserIds) => {
    setLocalOrg(prev => ({
      ...prev,
      [role]: selectedUserIds,
    }))
    handleUpdateOrganization(role, selectedUserIds)
  }
  const handleUpdateOrganization = (role, selectedUserIds) => {
    // const selectedUserIds = newUsers.map(user => user.id || user.user_uuid)
    const previousRoleState = localOrg[role] // Save the current state for rollback

    setIsUpdating(true)

    const updates = [
      {
        primaryKey: 'org_uuid',
        tableName: 'organizations',
        itemId: organization.org_uuid,
        fieldName: role,
        newValue: selectedUserIds,
      },
    ]

    updateItems(
      { updates },
      {
        onSuccess: () => {
          console.log('Update successful')
          queryClient.invalidateQueries(['organization', organization.org_uuid]) // Refresh server state
        },
        onError: err => {
          console.error('Update failed', err)
          // Rollback to previous state on error
          setLocalOrg(prev => ({
            ...prev,
            [role]: previousRoleState,
          }))
        },
        onSettled: () => {
          setIsUpdating(false)
        },
      }
    )
  }
  const handleNotify = async (role, userIds) => {
    /*
        userId: id,
        type: type,
        severity: severity,
        title: title,
        message: message,
        board: board || '',
        boardId: itemId,
        url: link || '',
    */

    const payload = await createMessagePayload(
      userIds,
      'team',
      'success',
      'Added Notification',
      `Added to team as ${role} | ${organization?.name}`,
      'organizations',
      organization?.name,
      organization?.org_uuid,
      organization?.photoUrl,
      `https://grobotic.app/organizations/${organization?.org_uuid}`
    )
    await addNotification(payload)
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <RenderOrgTeam
        key={localOrg ? localOrg.org_uuid : []}
        organization={localOrg} // Use the local state
        onRemove={handleRemove}
        onChange={handleChange}
        isUpdating={isUpdating}
      />
    </Box>
  )
}

export default TeamMembers

// import React, { useState } from 'react'
// import RenderOrgTeam from './RenderOrgTeam'
// import { useUpdateItems } from '../../api/aws/useUpdateItems'
// import { useQueryClient } from '@tanstack/react-query'
// import { Box } from '@mui/material'

// const TeamMembers = ({ organization }) => {
//   const [isUpdating, setIsUpdating] = useState(false)
//   const { mutate: updateItems } = useUpdateItems()
//   const queryClient = useQueryClient()
//   const handleUpdateOrganization = (role, newUsers) => {
//     setIsUpdating(true) // Set updating to true as the process starts

//     const selectedUserIds = newUsers.map(user => user.id || user.user_uuid)
//     console.log('newUserIds: ', newUserIds)

//     if (!Array.isArray(organization[role])) {
//       organization[role] = []
//     }

//     const updates = [
//       {
//         primaryKey: 'org_uuid',
//         tableName: 'organizations',
//         itemId: organization.org_uuid,
//         fieldName: role,
//         newValue: newUserIds,
//       },
//     ]

//     updateItems(
//       { updates: updates },
//       {
//         onSuccess: response => {
//           console.log('Update successful', response)

//           if (response && Array.isArray(response.updates)) {
//             response.updates.forEach(update => {
//               const { fieldName, updatedAttributes } = update

//               if (updatedAttributes && updatedAttributes[fieldName]) {
//                 console.log(`Updated ${fieldName}: `, updatedAttributes[fieldName])
//               }
//             })
//           } else {
//             console.warn('No updates found in the response:', response)
//           }

//           // queryClient.invalidateQueries(['/aws/organizations', organization.org_uuid])
//           setIsUpdating(false)
//         },
//         onError: err => {
//           console.error('Update failed', err)
//           setIsUpdating(false)
//         },
//         onSettled: () => {
//           setIsUpdating(false)
//         },
//       }
//     )
//   }
//   return (
//     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//       <RenderOrgTeam
//         key={organization ? organization.org_uuid : []}
//         organization={organization}
//         onUpdate={handleUpdateOrganization}
//         isUpdating={isUpdating}
//       />
//     </Box>
//   )
// }

// export default TeamMembers
